<template>
    <div id="viewer"></div>
</template>

<script>
    import {Viewer} from 'photo-sphere-viewer'
    import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers'
    import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
    import 'photo-sphere-viewer/dist/plugins/markers.css'
    export default {
        name: "Index",
        data(){
            return{
                viewer:'',
                imgurl1:require('../../assets/1.jpg'),
                imgurl2:require('../../assets/2.jpg'),
                imgurl3:require('../../assets/3.jpg'),
            }
        },
        mounted(){
            const _this = this
            this.viewer = new Viewer({
                container:document.querySelector('#viewer'),
                panorama:this.imgurl1,
                size:{
                    width: '200vw',
                    height: '100vh',
                },
                plugins: [
                    [MarkersPlugin, {
                        markers: [
                            {
                                id:'111',
                                tooltip:'点',
                                circle:10,
                                svgStyle : {
                                    fill:'rgba(255,255,0,0.3)',
                                    stroke:'yellow',
                                    strokeWidth:'2px',
                                },
                                longitude: Math.PI * -2,
                                latitude: -0.28,
                                anchor: 'center right',
                            }
                        ],
                    }],
                ],
            });

            const markersPlugin = this.viewer.getPlugin(MarkersPlugin);
            markersPlugin.on('select-marker', (e, marker) => {
                this.viewer.animate({
                    longitude: marker.config.longitude,
                    latitude: marker.config.latitude,
                    zoom: 100,
                    speed: '-2rpm',
                }).then(() =>
                    this.viewer.setPanorama(
                        this.imgurl2
                    ).then(() =>
                            markersPlugin.updateMarker({
                                id: marker.id,
                                longitude: -1.8,
                                latitude: -0.28,
                            }),
                        this.viewer.animate({
                            zoom: 50,
                            speed: '2rpm',
                        }).then(() =>
                                this.imgurl2 = this.imgurl3,
                            console.log("继续操作")
                        )
                    )
                )
            })
            // 点击全景图为全景图添加标记点
            _this.viewer.on('click',(e) => {
                markersPlugin.addMarker({
                    id: '#' + Math.random(),
                    longitude: e.args[0].longitude,
                    latitude: e.args[0].latitude,
                    image: 'http://photo-sphere-viewer.js.org/assets/pin-red.png',
                    width: 32,
                    height: 32,
                    anchor: 'bottom center',
                    tooltip: 'Generated pin',
                    data: {
                        generated: true
                    }
                })
            })
        }
    }
</script>

<style scoped>

</style>
